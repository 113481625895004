










import { Component, Vue } from "vue-property-decorator";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";
import { State } from "vuex-class";
import User from "@/entity/User";
import { Watch } from "vue-property-decorator";
import { debounce } from "lodash";
import UserService from "@/services/UserService";
import { Mutation } from "vuex-class";

@Component({
  components: { SectionTitle },
})
export default class ProfileView extends Vue {
  @State("user", { namespace: "auth" })
  user!: User;
  @Mutation("SET_ENABLED_NOTIFICATIONS", { namespace: "auth" })
  setEnabledNotifications!: (isEnabled: boolean) => void;

  enableEmailNotifications = false;

  async updateEmailNotifications() {
    await UserService.updateNotificationSettings({
      enableEmailNotifications: this.enableEmailNotifications,
    });
    this.setEnabledNotifications(this.enableEmailNotifications);
  }

  debounceUpdate = debounce(() => {
    this.updateEmailNotifications();
  }, 500);

  @Watch("enableEmailNotifications")
  onUserChanged() {
    this.debounceUpdate();
  }

  mounted() {
    this.enableEmailNotifications = this.user.enableEmailNotifications;
  }
}
